// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-wp-page-uri-js": () => import("./../../../src/pages/{wpPage.uri}.js" /* webpackChunkName: "component---src-pages-wp-page-uri-js" */),
  "component---src-templates-all-companies-template-jsx": () => import("./../../../src/templates/AllCompaniesTemplate.jsx" /* webpackChunkName: "component---src-templates-all-companies-template-jsx" */),
  "component---src-templates-companies-sector-template-jsx": () => import("./../../../src/templates/CompaniesSectorTemplate.jsx" /* webpackChunkName: "component---src-templates-companies-sector-template-jsx" */),
  "component---src-templates-company-template-jsx": () => import("./../../../src/templates/CompanyTemplate.jsx" /* webpackChunkName: "component---src-templates-company-template-jsx" */),
  "component---src-templates-crypto-template-jsx": () => import("./../../../src/templates/CryptoTemplate.jsx" /* webpackChunkName: "component---src-templates-crypto-template-jsx" */),
  "component---src-templates-estate-template-jsx": () => import("./../../../src/templates/EstateTemplate.jsx" /* webpackChunkName: "component---src-templates-estate-template-jsx" */),
  "component---src-templates-forex-template-jsx": () => import("./../../../src/templates/ForexTemplate.jsx" /* webpackChunkName: "component---src-templates-forex-template-jsx" */),
  "component---src-templates-newbie-template-jsx": () => import("./../../../src/templates/NewbieTemplate.jsx" /* webpackChunkName: "component---src-templates-newbie-template-jsx" */),
  "component---src-templates-stock-template-jsx": () => import("./../../../src/templates/StockTemplate.jsx" /* webpackChunkName: "component---src-templates-stock-template-jsx" */),
  "component---src-templates-trading-template-jsx": () => import("./../../../src/templates/TradingTemplate.jsx" /* webpackChunkName: "component---src-templates-trading-template-jsx" */)
}

